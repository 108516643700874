<template>
        <div class="form">
            <div class="bg-col">
                <div class="dy-text-box">
                    <div class="title-name">LOGO</div>
                                <VueCoreImageUpload
                                    class="logo-pic"
                                    :crop="false"
                                    compress="80"
                                    @imageuploaded="imageuploaded"
                                    :headers="headers"
                                    :max-file-size="5242880"
                                    :credentials="false"
                                    inputAccept="image/*"
                                    inputOfFile="file"
                                    :url="url"
                                    ref="upImg"
                                
                                >
                                    <img :src="pic" />
                                </VueCoreImageUpload>
                                <!-- <div class="logo-pic" v-else >
                                    <img :src="pic" />
                                </div> -->
                    <div class="logo-pic-jiantou">
                        >
                    </div>
                    <!-- <input  type="file"  name="pic" hidden ref='file' @change="onFileChange" /> -->
                </div>
                <div class="dy-text-box">
                    <div class="title-name">名称</div>
                    <input name="title"   v-model="title" value="title" />
                </div>
                <div class="dy-text-box">
                    <div class="title-name">手机号</div>
                    <input  name="phone" v-model="phone" value="phone" />
                </div>
                <div class="dy-text-box">
                    <div class="title-name">所在地区</div>
                    <div  @click.stop="show2 = true" style="width: 100%;text-align: right;">{{ model2 || "省，市，区" }}</div>
                    <!-- <input hidden :value="model2" /> -->
                    <CitySelect
                    v-model="show2"
                    :callback="result2"
                    :items="district"
                    provance=""
                    city=""
                    area=""
                    ></CitySelect>
                    <div class="iconfont icon-weizhi font-color-red"></div>
                </div>
                <div class="dy-text-box">
                    <div class="title-name">详情地址</div>
                    <textarea v-model="detailed_address">{{detailed_address}}</textarea>
                </div>
                <div class="dy-text-box">
                    <div class="title-name">营业时间</div>
                    <input name="title"   v-model="day_time" value="day_time" />
                </div>
                <div class="dy-text-box" style="border:unset">
                    <div class="title-name">上传图片</div>
                    <input placeholder="（最多传9张图）" disabled />
                </div>
                <div class="product_uploader">
                    <Uploader v-model="fileList" :after-read="afterRead" :before-read="beforeRead" :max-size="10000 * 1024" :max-count="9" @oversize="onOversize" />
                </div>

                <div class="dy-text-box">
                    <div class="title-names">显示门店</div>
                    
                </div>
            </div>
                <div class="btn">
                    <!-- <button class="anniu" @click="dtsave" ><span>保存</span></button> -->
                    <button class="anniu fa" @click="dtsubmit"><span>修改</span></button>
                </div>
        </div>
</template>
<script>
import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
import District from "ydui-district/dist/jd_province_city_area_id";
import { VUE_APP_API_URL } from "@utils";
import VueCoreImageUpload from "vue-core-image-upload";
import { uploadImg,getBusinessData,saveBusinessData } from "@api/tasting";
import { Icon, Uploader , Toast } from 'vant';
import { Image as VanImage } from 'vant';

export default {
        name: "store",
        components: {
             Icon, Uploader , Toast ,VanImage ,CitySelect,VueCoreImageUpload
        },
        data: function() {
            return {
                url: `${VUE_APP_API_URL}/upload/image`,
                headers: {
                    "Authori-zation": "Bearer " + this.$store.state.app.token
                },
                pic:'',
                title:'',
                detailed_address:'',
                phone:'',
                day_time:'',

                show2: false,
                model2: "",
                district: District,
                address: {
                    
                },
                title:'',
                message:'',
                id:0,
                fileList:[],
                storeData:[]
            };
        },
        created: function() {
            //this.id=this.$route.query.id;
        },  
        mounted: function() {
            this.getBusinessData();
        },
        methods: {
            imageuploaded(res) {
            if (res.status !== 200){return this.$dialog.error(res.msg || "上传图片失败");}
            //this.$set(this.storeData, "image", res.data.url);
            this.pic = res.data.url;
            },
            onFileChange(file){
                console.log(file);return;
            },
            result2(ret) {
                this.model2 = ret.itemName1 + " " + ret.itemName2 + " " + ret.itemName3;
                this.address.province = ret.itemName1;
                this.address.city = ret.itemName2;
                this.address.district = ret.itemName3;
            },

            getBusinessData:function(){
                    getBusinessData({
                        
                    }).then(res => {
                        this.storeData = res.data;
                        let List =[];
                        res.data.images.forEach(function(item, i){
                              List[i] = {'url':item};
                        })
                        this.fileList=List;
                        this.id = res.data.id;
                        this.pic = res.data.image;
                        this.title= res.data.title;
                        this.detailed_address = res.data.detailed_address;
                        this.phone= res.data.phone;
                        this.day_time= res.data.day_time;
                        this.model2 = res.data.address[0] + "," + res.data.address[1] + "," + res.data.address[2];
                        this.address.province = res.data.address[0];
                        this.address.city = res.data.address[1];
                        this.address.district = res.data.address[2];
                        console.log(res.data.address[0])
                    })
            }, 
            //提交
            dtsubmit(){
                if(!this.title){
                    Toast.fail({
                        message: '请填写店名',
                        forbidClick: true,
                    });
                    return
                }
                if(!this.pic){
                    Toast.fail({
                        message: '请上传LOGO',
                        forbidClick: true,
                    });
                    return
                }
                if(!this.address){
                    Toast.fail({
                        message: '请选择地区',
                        forbidClick: true,
                    });
                    return
                }
                if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.phone)) {
                    return this.$dialog.toast({ mes: "请填写正确的手机号" });
                }
                // if(!this.detailed_address){
                //     Toast.fail({
                //         message: '请填写详情地址',
                //         forbidClick: true,
                //     });
                //     return
                // }
                if(this.fileList.length==0){
                    Toast.fail({
                        message: '请上传图片',
                        forbidClick: true,
                    });
                    return
                }
                let imagearr=[];
                this.fileList.forEach(function(item) {
                    imagearr.push(item.url)
                });
                Toast.loading({
                    duration: 0,
                    message: '修改中...',
                    forbidClick: true,
                });
                saveBusinessData({
                    id: this.id, //id
                    title:this.title,
                    logo:this.pic,
                    phone:this.phone,
                    day_time:this.day_time,
                    province:this.address.province,
                    city:this.address.city,
                    district:this.address.district,
                    detail: this.detailed_address, //详情地址
                    images: imagearr, //图片集合
                }).then(res => {
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                    var that = this;
                    setTimeout(function(){ 
                        that.$router.back(-1);
                    }, 2000);
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                console.log(file);
                let index = this.fileList.length - 1;
                file.status = 'uploading';
                file.message = '上传中...';
                var formData = new FormData();
    　　        formData.append('file', file.file);
                Toast.loading({
                    duration: 0,
                    message: '上传中',
                    forbidClick: true,
                });
                uploadImg(formData).then(res => {
                    Toast.clear();
                    this.fileList[index] = res.data;
                    file.status = '';
                    file.message = '';
                    console.log(this.fileList)
                })
                .catch(err => {
                    this.fileList.splice(index,1);
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            beforeRead(file) {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    Toast('只允许上传jpg/png格式的图片！');
                    return false;
                }
                return true;
            },
            onOversize(file) {
                console.log(file);
                Toast('图片大小不能超过 10M');
            },
        }
    }
</script>
<style scoped lang="scss">
    ::v-deep{
        .van-icon-star{font-size: 0.5rem!important;}
        .van-uploader__upload, .van-uploader__preview-image{border: 2px solid #eee;border-radius: 0.2rem;}
        .Scenes{
            .van-picker__columns{display:none;}
        }
    }
    .form .bg-col{
        background-color: #fff;
    }
    .dy-text-box{
        width: 95%;
        display: flex;
        padding: 0.3rem;
        font-size: 0.3rem;
        border-bottom: 1px solid #eee;
        margin: 0 auto;
    }
    .dy-text-box .title-name{
        width: 40%;
    }
    .dy-text-box .title-names{
        width: 40%;
    }
     .dy-text-box .title-name:before {
        content: '* ';
        color: red;
     }
    .dy-text-box input{width: 60%;text-align: right;}
    .dy-text-box textarea{
        height: 2rem;width: 60%;text-align: right;
    }
    .linename{
        display: block;
        
        margin: 0 0.3rem;
    }
    .btn{display: flex;flex-wrap: wrap;height: 1.6rem;}
    .btn .anniu{flex-direction:row;justify-content:space-evenly;margin-top: 0.3rem;width: 100%;}
    .btn .anniu span{background-color: #f00;color: #fff;border-radius: 0.4rem 0.4rem;padding:0.2rem 1.5rem ;}
    .product_uploader{padding:0.3rem;}
    .logo-pic{
        position:absolute;
        width: 0.8rem;
        height: 0.8rem;
        right: 6%;
        top: 0.1rem;
        img{
            width: 100%;
            height: 100%;
            border-radius:0.8rem;
        }
    }
    .logo-pic-jiantou{
        position:absolute;
        right: 2.5%;
        font-weight: 600;
    }
</style>